<template>
<div style="margin-top: 100px">
    <BasePageBreadCrumb :pages="pages" title="permissões" :menu="menu"/>
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card card-bg-trans">
                        <div class="card-body pt-4 px-0">
                            <form class="px-2 needs-validation" novalidate="" @submit.prevent="save">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <a href="#" class="btn btn-primary" id="permissao" data-bs-toggle="modal" data-bs-target="#exampleModal">Adicionar permissão</a>
                                            </div>
                                        </div>
                                        <!-- Modal -->
                                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Permissão</h5>
                                                        
                                                        <button type="button" class="btn-close btn btn-primary" data-bs-dismiss="modal" aria-label="Close">X</button>
                                                   
                                                    </div>

                                                    <div class="modal-body">
                                                        <div class="w-100">
                                                            <form>
                                                                <div  data-offset="0" class="position-relative mt-2">
                                                                    <input type="text" class="form-control" name="name" v-model="permissaolista['name']" placeholder="nome"></br>
                                                                    <input type="text" class="mt-2 form-control" name="description" placeholder="descrição" v-model="permissaolista['description']">
                                                                    <div class="col-12 p-0" v-for="(permissoes, id) in permissionslista">                                 
                                                                        <div class="col-12 pt-4 pb-1 px-3 font-b-7">{{permissoes[0]["resource_name"]}}</div>
                                                                            <div class="col-12 col-md-10 col-lg-8 p-1">
                                                                                <div class="col-12">
                                                                                    <div class="row" v-for="(permissao, id2) in permissoes">                               
                                                                                        <div class="col-12 col-md-4 col-lg-12 text-truncate p-1">
                                                                                            <div class="custom-control custom-checkbox">                            
                                                                                                <input type="checkbox" class="custom-control-input" name="permissao" :id="permissoes[0]['resource_name']+id2" :value="permissionslista[id][id2]['id']" v-model="permissoessalvar">
                                                                                                <label class="custom-control-label" :for="permissoes[0]['resource_name']+id2">{{ permissao["name"] }}</label>
                                                                                            </div>
                                                                                        </div>                                                
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        <div class="border-line-div"></div>
                                                                    </div>
                                                                </div>
                                                                <button type="button" class="btn-close btn btn-primary" @click="save()" aria-label="Close">Salvar</button>

                                                            </form>
                                                        </div>

                                                    </div>
                                                    
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn-close btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Fechar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                               
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">                               
                                
                                    <div class="d-flex">                                        
                                        <div class="col-4 pad-content-list">
                                            <h6 class="text-truncate card-body-list-title">Nome</h6>
                                        </div>
                                        <div class="col-4 pad-content-list">
                                            <h6 class="text-truncate card-body-list-title">Descrição</h6>
                                        </div>
                                        <div class="col-4 pad-content-list">
                                            <h6 class="text-truncate card-body-list-title">Permissão</h6>
                                        </div>
                                    </div>                                
                                                             
                                <div class="card-list" v-for="item in permissionsgroupolista" :key="item.id"> 
                                    <div class="card-body-list"> 
                                        <div class="col-12 p-0 align-self-center">
                                            <div class="col-12">
                                                <div>
                                                        <div class="d-flex">
                                                        
                                                                <div class="col-4 pad-content-list">
                                                                    <h6 class="text-truncate card-body-list-title">{{ item.name }}</h6>
                                                                </div>
                                                                <div class="col-4 pad-content-list">
                                                                    <h6 class="text-truncate card-body-list-title">{{ item.description}}</h6>
                                                                </div>
                                                                <div class="col-4 pad-content-list">                                                                   
                                                                    <h6 class="text-truncate card-body-list-title" v-for="permissao in item.user_permissions">{{permissao.name}}</h6>
                                                                </div>
                                                           
                                                    
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-4 align-self-center order-2">
                                                       
                                                    </div>
                                                    {{ item.id }}
                                                    <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                                        <b-link @click="editarPermissao(item.id)" class="btn btn-dark rounded-circle btn-circle font-20 card-body-list-cta" >+</b-link>
                                                        <a class="btn btn-danger text-white rounded-circle btn-circle font-16 ml-2" href="javascript:void(0);" @click="removePermission(item.id)"><i class="far fa-trash-alt"></i></a>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 text-center p-4" v-if="permissionslista.length == 0">
                                Nenhuma permissão encontrada.
                </div>
                    </div>
                </div>
               
            </div>
            

            <div class="col-12">
                <BasePagination
                    v-if="permissionslista.length > 0"
                    :totalPages="pagination.totalPages"
                    :activePage="pagination.currentPage"
                    @to-page="toPage"
                    @per-page="perPage" />
            </div>
            </div>
        </div>
</div>
</template>
<script>
import AccessPermissionService from "@/services/resources/AccessPermissionService";
const service = AccessPermissionService.build();
import AccessPermissionGroupService from "@/services/resources/AccessPermissionGroupService";
const service2 = AccessPermissionGroupService.build();
import Cookies from 'js-cookie';
import store from "@/store/index";
export default {
    computed: {
        company_id() {
            return store.getters.getProfile
        }
    },
    data(){
        return{
            permissionslista: [],
            permissaolista:{name: "", description:""},
            permissoessalvar:[],
            permissionsgroupolista: [],
            pagination: {
                totalPages: 1,
                currentPage: 1,
                perPage: 10,
                itens: 0
            },
            search: null,
            pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'permissões',
                    to: 'TimePermission'
                }
            ],
            menu:[
                {
                    name: 'Permissões',
                    to: 'TimePermission'
                }
            ]
        }
    },
    methods:{
        toPage(page) {
            this.pagination.currentPage = page;
            this.fetchPermissions();
        },
        editarPermissao(id){
            let permissoes = []
            this.permissionsgroupolista.forEach(ele => {
                console.log(ele);
                if(ele.id==id){
                   ele.user_permissions.forEach(function(ele2) {
                    console.log(ele2);
                    permissoes[permissoes.length]=ele2.id
                   })
                    this.permissaolista['description'] = ele.description
                    this.permissaolista['name']=ele.name
                    this.permissaolista['id'] = ele.id
                }
            });

            this.permissoessalvar = permissoes
                console.log(this.permissoessalvar);
                document.getElementById("permissao").click()
        },
        perPage(qtd) {
            this.pagination.currentPage = 1;
            this.pagination.perPage = qtd;

            this.fetchPermissions();
        },
        removePermission(id){

           this.$bvModal.msgBoxConfirm('Tem certeza que deseja excluir essa permissão?')
          .then(value => {

                if(value) {
                    let data = {
                        id: id
                    };

                    service
                    .destroy(data)
                    .then(resp => {
                        this.$bvToast.toast('Sua permissão foi excluída com sucesso!', {
                            title: 'Excluir permissão',
                            autoHideDelay: 5000,
                            type: 'success'
                        })
                        this.fetchPermissions();
                    })
                    .catch(err => {
                        this.$bvToast.toast('Sua permissão não pode ser excluída.', {
                            title: 'Excluir permissão',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })
                }
               
          })
          .catch(err => {
            // An error occurred
          })

        },
        save(){

         //   console.log(this.permission);
            this.$validator.validateAll().then((result) => {
                if (result) {
                    console.log(this.permissaolista)
                  let permissao = this.permissaolista
                  permissao['user_permissions'] = this.permissoessalvar
                  let company_id = this.company_id;
                  console.log(company_id);
                  console.log(company_id);
                  permissao["company_id"] = company_id.company_id;
                  console.log(permissao);
                    if(permissao.id != null){
                        service2
                        .update(permissao)
                        .then(resp => {
                            this.$bvToast.toast('Sua permissão foi criada com sucesso!', {
                                title: 'Criação de permissão',
                                autoHideDelay: 5000,
                                type: 'success'
                            });
                            this.fetchPermissions();
                            this.fetchGroupPermissions();
                        })
                        .catch(err => {
                            this.$bvToast.toast('Houve um erro inesperado, tente novamente mais tarde.', {
                                title: 'Criação de permissão',
                                autoHideDelay: 5000,
                                type: 'danger'
                            })
                        })
                    }else {
                        service2
                        .create(permissao)
                        .then(resp => {
                            this.$bvToast.toast('Sua permissão foi criada com sucesso!', {
                                title: 'Criação de permissão',
                                autoHideDelay: 5000,
                                type: 'success'
                            });
                            this.fetchPermissions();
                            this.fetchGroupPermissions();
                        })
                        .catch(err => {
                            this.$bvToast.toast('Houve um erro inesperado, tente novamente mais tarde.', {
                                title: 'Criação de permissão',
                                autoHideDelay: 5000,
                                type: 'danger'
                            })
                        })
                    }
                }
            });
        },
        fetchPermissions(){

            let data = {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage
            }

            if(this.search){
                data['search'] = this.search;
            }

             service
                .search(data)
                .then(resp => {
                    let permissoes  = []
                    for (var chave in resp["data"]) {
                        if (resp["data"].hasOwnProperty(chave)) {
                            permissoes[permissoes.length] = resp["data"][chave]
                        }
                    }

                    this.permissionslista = permissoes;
                    console.log(permissoes);
                    this.pagination.totalPages = resp.last_page;
                    this.pagination.itens = resp.total;
                })
                .catch(err => {
                    console.log(err)
                })
        },
        fetchGroupPermissions(){

            let data = {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage
            }

            if(this.search){
                data['search'] = this.search;
            }

            service2
                .search(data)
                .then(resp => {
                    let permissoes  = []
                   

                    this.permissionsgroupolista = resp["data"];
                    console.log(permissoes);
                    this.pagination.totalPages = resp.last_page;
                    this.pagination.itens = resp.total;
                })
                .catch(err => {
                    console.log(err)
                })
            }
    },
    mounted(){
        this.fetchPermissions();
        this.fetchGroupPermissions();
    }
}
</script>